@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.modal {
  margin-top: 6%;
}
.modal .modal-content button {
  margin-bottom: 6px;
}
.modal .modal-content .closeIcon {
  color: #95989A;
}
.modal .modal-content .lockIcon {
  color: #000000;
}
.modal .modal-content .modal-title {
  border-bottom: 0px;
}
.modal .modal-content .survey-body {
  background: #A3DED2;
}
.modal .modal-content .extend-landing-body {
  padding-left: 40px;
  padding-right: 40px;
}
@media (max-width: 767px) {
  .modal .modal-content .extend-landing-body {
    padding-left: 20px;
    padding-right: 20px;
  }
}
.modal .modal-content .extend-landing-title {
  padding-left: 20px;
}
.modal .modal-content .extend-landing-checkmark {
  color: #71C186;
  margin-bottom: 20px;
}
.modal .modal-content .extend-landing-text-wrapper {
  padding-top: 35px;
  text-align: left;
  line-height: 1.3;
  font-size: 12px;
  font-weight: 700;
}
.modal .modal-content .extend-landing-cost-wrapper {
  text-align: left;
  margin: 0px auto;
  font-size: 12px;
  position: relative;
}
.modal .modal-content .extend-landing-cost-wrapper p::before,
.modal .modal-content .extend-landing-cost-wrapper p::after {
  content: "";
  width: 17%;
  position: absolute;
  left: 20px;
  top: 50%;
}
.modal .modal-content .extend-landing-cost-wrapper p::after {
  border-bottom: 2px solid #A3DED2;
  -webkit-transform: skewY(10deg);
  transform: skewY(10deg);
}
.modal .modal-content .extend-landing-cost-wrapper span {
  font-size: 16px;
}
.modal .modal-content .form-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
  justify-content: left;
  text-align: left;
}
.modal .modal-content .had-baby-subtitle {
  font-style: italic;
  font-size: 20px;
  font-weight: 100;
  padding: 0 20px;
  margin: 0;
}
@media (max-width: 767px) {
  .modal .modal-content .had-baby-subtitle {
    font-size: 12px;
    padding: 0;
  }
}
.modal .modal-content .two-button-modal-button {
  width: 100%;
}
@media (max-width: 767px) {
  .modal .modal-content .two-button-modal-button {
    font-size: 14px;
  }
}
.modal .modal-content h1 {
  font-size: 24px;
  line-height: 1.25;
  padding: 20px;
  padding-bottom: 0;
}
.modal .modal-content ul {
  margin: 1rem auto 0;
  text-align: left;
  width: 80%;
}
.modal .modal-content ul li {
  list-style-position: inside;
}
.modal .modal-content .had-baby-subtitle-div {
  position: relative;
  top: -10px;
}
.modal .modal-content .had-baby-img-right,
.modal .modal-content .had-baby-img-left,
.modal .modal-content .arrow {
  padding: 0;
}
.modal .modal-content .arrow {
  margin-top: 70px;
}
@media (max-width: 767px) {
  .modal .modal-content .arrow {
    margin-top: 50px;
  }
  .modal .modal-content .arrow svg {
    width: 50%;
    height: auto;
  }
}
.modal .modal-content .breastPumpConfirmSecondary {
  margin-top: 10px;
}
.modal .modal-content .breastPumpConfirmMain {
  color: #231F20 !important;
}
.modal .btn {
  white-space: normal !important;
  word-wrap: break-word;
}
.modal .btn-primary {
  width: 100%;
}

.modal-title .modal-title-item {
  display: inline;
}

.modal-content h1 {
  font-size: 32px;
  line-height: 1.1em;
  font-weight: 600;
}

.checkoutModal {
  margin-top: 0%;
}
.checkoutModal .checkoutModalBody {
  overflow: hidden;
}
.checkoutModal .modal-dialog {
  /* This is a hack for making the dialog look modal, but really it
     is pushing the footer down and just starting on the page higher over
     the header. Probably a better way to do this. (i.e. without 'relative')
  */
  position: relative;
}
.checkoutModal .modal {
  overflow: visible;
}
.checkoutModal .modal-content {
  padding: 0;
}
@media (max-width: 992px) {
  .checkoutModal .modal-content {
    top: 20px;
  }
}
.checkoutModal .modal-body {
  padding-top: 0;
}
@media screen and (min-width: 992px) {
  .checkoutModal .modal-body {
    margin-top: 15px;
  }
}
.checkoutModal .modal-header {
  position: absolute;
  right: 0;
  padding: 15px;
  z-index: 1;
}

#promo-product-modal,
#promo-product-modal-vitamin {
  margin-top: 0%;
  max-width: 60vw !important;
  position: absolute;
}
#promo-product-modal .text-m,
#promo-product-modal-vitamin .text-m {
  display: none;
}
#promo-product-modal #starRating,
#promo-product-modal-vitamin #starRating {
  justify-content: center !important;
  text-align: center !important;
  display: flex;
}
#promo-product-modal.modal,
#promo-product-modal-vitamin.modal {
  overflow: visible !important;
}
@media (max-width: 767px) {
  #promo-product-modal,
  #promo-product-modal-vitamin {
    max-width: 90vw !important;
  }
}
#promo-product-modal .modal-header,
#promo-product-modal-vitamin .modal-header {
  padding: 15px;
}
#promo-product-modal .modal-body,
#promo-product-modal-vitamin .modal-body {
  padding-top: 5px;
  padding-bottom: 5px;
}
#promo-product-modal .noThanksButton,
#promo-product-modal-vitamin .noThanksButton {
  text-align: center;
  text-decoration: underline;
  border: none;
  background: white;
}
#promo-product-modal .promoProductTitleRow,
#promo-product-modal-vitamin .promoProductTitleRow {
  padding-top: 10px;
  justify-content: center;
}
#promo-product-modal .promoProductRow,
#promo-product-modal-vitamin .promoProductRow {
  justify-content: space-around;
}
#promo-product-modal .promoProductSubTitleRow,
#promo-product-modal-vitamin .promoProductSubTitleRow {
  justify-content: center;
}
#promo-product-modal .promoProductSubTitleCol,
#promo-product-modal .promoProductSubTitleColVitamin,
#promo-product-modal-vitamin .promoProductSubTitleCol,
#promo-product-modal-vitamin .promoProductSubTitleColVitamin {
  position: relative;
  display: inline-block;
  color: #95989A;
}
#promo-product-modal .promoProductSubTitleCol,
#promo-product-modal-vitamin .promoProductSubTitleCol {
  color: #95989A;
}
@media (max-width: 767px) {
  #promo-product-modal .promoProductSubTitleCol,
  #promo-product-modal-vitamin .promoProductSubTitleCol {
    font-size: 10px;
  }
}
@media screen and (max-width: 320px) {
  #promo-product-modal .promoProductSubTitleCol,
  #promo-product-modal-vitamin .promoProductSubTitleCol {
    font-size: 8px;
  }
}
@media (max-width: 767px) {
  #promo-product-modal .promoProductSubTitleColVitamin,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin {
    font-size: 8px;
  }
}
@media screen and (max-width: 320px) {
  #promo-product-modal .promoProductSubTitleColVitamin,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin {
    font-size: 7px;
  }
}
#promo-product-modal .promoProductSubTitleCol:before,
#promo-product-modal .promoProductSubTitleCol:after,
#promo-product-modal .promoProductSubTitleColVitamin:before,
#promo-product-modal .promoProductSubTitleColVitamin:after,
#promo-product-modal-vitamin .promoProductSubTitleCol:before,
#promo-product-modal-vitamin .promoProductSubTitleCol:after,
#promo-product-modal-vitamin .promoProductSubTitleColVitamin:before,
#promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
  content: "";
  position: absolute;
  top: 50%;
  height: 1px;
  background: #95989A;
}
#promo-product-modal .promoProductSubTitleCol:before,
#promo-product-modal .promoProductSubTitleCol:after,
#promo-product-modal-vitamin .promoProductSubTitleCol:before,
#promo-product-modal-vitamin .promoProductSubTitleCol:after {
  width: 75%;
}
@media screen and (max-width: 1280px) and (min-width: 992px) {
  #promo-product-modal .promoProductSubTitleCol:before,
  #promo-product-modal .promoProductSubTitleCol:after,
  #promo-product-modal-vitamin .promoProductSubTitleCol:before,
  #promo-product-modal-vitamin .promoProductSubTitleCol:after {
    width: 50%;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  #promo-product-modal .promoProductSubTitleCol:before,
  #promo-product-modal .promoProductSubTitleCol:after,
  #promo-product-modal-vitamin .promoProductSubTitleCol:before,
  #promo-product-modal-vitamin .promoProductSubTitleCol:after {
    width: 20%;
  }
}
@media (max-width: 500px) {
  #promo-product-modal .promoProductSubTitleCol:before,
  #promo-product-modal .promoProductSubTitleCol:after,
  #promo-product-modal-vitamin .promoProductSubTitleCol:before,
  #promo-product-modal-vitamin .promoProductSubTitleCol:after {
    width: 15%;
  }
}
#promo-product-modal .promoProductSubTitleCol:before,
#promo-product-modal .promoProductSubTitleColVitamin:before,
#promo-product-modal-vitamin .promoProductSubTitleCol:before,
#promo-product-modal-vitamin .promoProductSubTitleColVitamin:before {
  right: 100%;
  margin-right: 15px;
}
@media (max-width: 767px) {
  #promo-product-modal .promoProductSubTitleCol:before,
  #promo-product-modal .promoProductSubTitleColVitamin:before,
  #promo-product-modal-vitamin .promoProductSubTitleCol:before,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:before {
    margin-right: 5px;
  }
}
#promo-product-modal .promoProductSubTitleCol:after,
#promo-product-modal .promoProductSubTitleColVitamin:after,
#promo-product-modal-vitamin .promoProductSubTitleCol:after,
#promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
  left: 100%;
  margin-left: 15px;
}
@media (max-width: 767px) {
  #promo-product-modal .promoProductSubTitleCol:after,
  #promo-product-modal .promoProductSubTitleColVitamin:after,
  #promo-product-modal-vitamin .promoProductSubTitleCol:after,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
    margin-left: 5px;
  }
}
#promo-product-modal .promoProductSubTitleColVitamin:before,
#promo-product-modal .promoProductSubTitleColVitamin:after,
#promo-product-modal-vitamin .promoProductSubTitleColVitamin:before,
#promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
  width: 55%;
}
@media screen and (max-width: 1280px) and (min-width: 992px) {
  #promo-product-modal .promoProductSubTitleColVitamin:before,
  #promo-product-modal .promoProductSubTitleColVitamin:after,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:before,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
    width: 28%;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  #promo-product-modal .promoProductSubTitleColVitamin:before,
  #promo-product-modal .promoProductSubTitleColVitamin:after,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:before,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
    width: 18%;
  }
}
@media screen and (max-width: 500px) {
  #promo-product-modal .promoProductSubTitleColVitamin:before,
  #promo-product-modal .promoProductSubTitleColVitamin:after,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:before,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
    width: 16%;
  }
}
#promo-product-modal .promoProductTitleCol,
#promo-product-modal-vitamin .promoProductTitleCol {
  width: 100%;
  padding: 0;
}
@media (max-width: 767px) {
  #promo-product-modal .promoProductTitle,
  #promo-product-modal-vitamin .promoProductTitle {
    font-size: 18px;
  }
}
#promo-product-modal .postPurchaseSubmit,
#promo-product-modal-vitamin .postPurchaseSubmit {
  margin-top: 5px;
  background-color: #75BDB0;
  width: 60%;
}
@media (max-width: 767px) {
  #promo-product-modal .postPurchaseSubmit,
  #promo-product-modal-vitamin .postPurchaseSubmit {
    width: 80%;
  }
}
#promo-product-modal .postPurchaseSubmit:hover,
#promo-product-modal-vitamin .postPurchaseSubmit:hover {
  background-color: #95989A;
}
#promo-product-modal .noThanksButtonRow,
#promo-product-modal-vitamin .noThanksButtonRow {
  justify-content: center;
  padding: 20px 15px;
  text-align: center;
}
@media (max-width: 767px) {
  #promo-product-modal .noThanksButtonRow,
  #promo-product-modal-vitamin .noThanksButtonRow {
    font-size: 12px;
  }
}

@media screen and (min-width: 992px) {
  #promo-product-modal-vitamin {
    max-width: 40vw !important;
  }
  #promo-product-modal-vitamin .productImage {
    width: 200px;
  }
}
#promo-product-modal-vitamin .promoProductSubTitleColVitamin:before,
#promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
  width: 20%;
}
@media screen and (max-width: 1280px) and (min-width: 992px) {
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:before,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
    width: 14%;
  }
}
@media screen and (max-width: 992px) and (min-width: 768px) {
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:before,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
    width: 9%;
  }
}
@media screen and (max-width: 500px) {
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:before,
  #promo-product-modal-vitamin .promoProductSubTitleColVitamin:after {
    width: 8%;
  }
}

#postPurchasePromoSuccess {
  overflow: hidden;
}
#postPurchasePromoSuccess .postPurchaseSuccesHr {
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-left: -50vw;
  margin-right: -50vw;
  margin-top: 50px;
}
#postPurchasePromoSuccess .modal-header {
  padding-top: 0;
  padding-bottom: 0;
}
#postPurchasePromoSuccess .modal-body {
  padding-top: 0;
}
#postPurchasePromoSuccess .postPurchaseSuccessTitle {
  font-size: 30px;
}
@media (max-width: 767px) {
  #postPurchasePromoSuccess .postPurchaseSuccessTitle {
    font-size: 20px;
  }
}
#postPurchasePromoSuccess .postPurchaseSuccesSubTitleDiv {
  font-size: 12px;
}
@media (max-width: 767px) {
  #postPurchasePromoSuccess .postPurchaseSuccesSubTitleDiv {
    font-size: 8px;
  }
}
#postPurchasePromoSuccess .postPurchaseSuccesSubTitleDiv > span {
  position: relative;
  display: inline-block;
}
#postPurchasePromoSuccess .postPurchaseSuccesSubTitleDiv > span:before,
#postPurchasePromoSuccess .postPurchaseSuccesSubTitleDiv > span:after {
  content: "";
  position: absolute;
  top: 50%;
  height: 1px;
  width: 8%;
  background: #95989A;
}
#postPurchasePromoSuccess .postPurchaseSuccesSubTitleDiv > span:before {
  right: 100%;
  margin-right: 15px;
}
@media (max-width: 767px) {
  #postPurchasePromoSuccess .postPurchaseSuccesSubTitleDiv > span:before {
    margin-right: 5px;
  }
}
#postPurchasePromoSuccess .postPurchaseSuccesSubTitleDiv > span:after {
  left: 100%;
  margin-left: 15px;
}
@media (max-width: 767px) {
  #postPurchasePromoSuccess .postPurchaseSuccesSubTitleDiv > span:after {
    margin-left: 5px;
  }
}

#updateCCModal .withComponentBody,
#cancelStepSurvey .withComponentBody {
  max-height: calc(100vh - 210px);
  overflow-y: auto;
}

.promo-product-modal {
  margin-top: 0;
}
@media (max-width: 500px) {
  .promo-product-modal {
    margin-left: 3%;
  }
}

.breastPumpImage {
  width: 100%;
  border: 1px solid #D6D6D6;
}
@media (max-width: 500px) {
  .breastPumpImage {
    width: 80%;
  }
}

.smsOptInModalHeader {
  padding-bottom: 0px;
}

.smsOptInTitle {
  width: 100%;
  text-align: center;
  margin: 0 auto;
  font-size: 24px;
}

.smsOptInDetailsDiv {
  text-align: left;
  height: 500px;
  overflow-y: scroll;
}
.smsOptInDetailsDiv h2 {
  font-size: 24px;
}

.termsLink {
  color: #3C7C70;
  font-weight: 700;
}

@media (max-width: 767px) {
  #preCheckoutPromoModal {
    position: relative;
    display: flex;
    flex-direction: column;
    margin-top: 15%;
    margin-bottom: 15%;
  }
}
#preCheckoutPromoModal h1 {
  font-size: 24px;
  color: #3C7C70;
  margin-bottom: -10px;
}
#preCheckoutPromoModal .withComponentBody {
  padding-top: 0px !important;
}

.becomeASubscriberModal .mainButton {
  margin-top: 30px;
}
.becomeASubscriberModal .contentBody {
  font-size: 16px;
}
.becomeASubscriberModal .helperText {
  font-size: 12px;
  font-style: italic;
  margin-top: 9px;
  margin-bottom: -10px;
}
.becomeASubscriberModal .helperText p {
  margin: 0;
}
@media (max-width: 500px) {
  .becomeASubscriberModal .modal-title {
    padding-bottom: 0;
  }
}

#recurringAddOnThankYou .mainButton {
  margin-top: 30px;
}
