@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.bbFormGroup {
  position: relative;
  background-color: #F4F4F4;
  border-radius: 8px;
  /* Let the errors show through */
  /**
    IE Overrides
    https://www.mediacurrent.com/blog/pro-tip-how-write-conditional-css-ie10-and-11/
  **/
  /* Set the fields to have a general grey background */
  /* start styles for optional components */
  /* end styles for optional components */
  /* The labels are absolute positioned to allow them to both appear as
      placeholders and labels by moving the placeholder position to the label
      position on focus or on input. Input class is set in js in field-group. */
  /* Labels in field-group are after the input so we can use a sibling
      selector. This is how the label should look as a label, not a
      placeholder. */
}
.bbFormGroup .form-control.is-invalid,
.bbFormGroup .was-validated .form-control:invalid {
  border: 1px solid #dc3545;
}
.bbFormGroup input,
.bbFormGroup select,
.bbFormGroup textarea {
  border-radius: 12.5px;
  padding: 20px 0px 20px 0.75rem;
}
.bbFormGroup select {
  background-color: #F4F4F4;
  padding: 0px 20px 0px 0.75rem;
  margin-top: 4px;
}
@media (max-width: 767px) {
  .bbFormGroup select {
    min-height: 40px;
    margin-top: 0px;
  }
}
@media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
  .bbFormGroup {
    /** IE doesn't handle height and padding well for inputs **/
  }
  .bbFormGroup input,
  .bbFormGroup select {
    padding: 2px 0px 2px 0.75rem;
  }
}
.bbFormGroup .form-control {
  background-color: #F4F4F4;
  border-radius: 8px;
}
.bbFormGroup .optionalFormControl {
  background-color: white;
  padding-top: 0px;
  height: 10px;
}
.bbFormGroup .optionalFormLabel {
  font-size: 75%;
  text-transform: uppercase;
  margin: 0px;
  margin-left: 0.75rem;
  position: absolute;
  top: 0;
  left: 0;
}
.bbFormGroup .optionalFormControl:focus {
  height: calc(1.5em + 0.75rem + 2px);
  padding-top: 20px;
}
.bbFormGroup .optionalFormLabel::before {
  content: "+ ";
}
.bbFormGroup label {
  transition: margin-top 0.25s, font-size 0.25s;
  position: absolute;
  margin: 0px;
  margin-left: 0.75rem;
  top: 0;
  left: 0;
  margin-top: calc((1.5em + 0.75rem + 2px) / 5);
  height: calc(1.5em + 0.75rem + 2px);
  overflow: hidden;
  cursor: text;
}
.bbFormGroup input:focus + .labelWithoutInput,
.bbFormGroup textarea:focus + .labelWithoutInput,
.bbFormGroup .labelWithInput {
  margin-top: -8px;
  font-size: 75%;
}
.bbFormGroup input:focus,
.bbFormGroup textarea:focus {
  background: white !important;
}
.bbFormGroup .invalid-feedback {
  padding-left: 0.75rem;
}
.bbFormGroup .readOnly {
  background-color: #F4F4F4 !important;
}
.bbFormGroup .readOnly:focus,
.bbFormGroup .readOnly:focus {
  background: #F4F4F4 !important;
}
.bbFormGroup .passwordInput {
  padding-right: 10%;
}
@media (max-width: 992px) {
  .bbFormGroup .passwordInput {
    padding-right: 15%;
  }
}
@media (max-width: 767px) {
  .bbFormGroup .passwordInput {
    padding-right: 7.5%;
  }
}
@media (max-width: 500px) {
  .bbFormGroup .passwordInput {
    padding-right: 10%;
  }
}
.bbFormGroup .svg-inline--fa {
  color: #95989A;
  position: absolute;
  top: 20px;
  right: 2.5%;
  max-width: 15px;
}
@media (max-width: 767px) {
  .bbFormGroup .svg-inline--fa {
    max-width: 18px;
  }
}
.bbFormGroup .svg-inline--fa:hover {
  color: #231F20;
}
.bbFormGroup .svg-inline--fa:focus {
  outline: none;
  color: #231F20;
}
