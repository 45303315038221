@mixin border-box {
  border: 1px solid $ruleColor;
  border-radius: 8px;
  padding: 20px;
  margin: 0;
}

@mixin select-box {
  transition: all 0.2s;

  @media screen and (min-width: $break-medium) {
    &:hover {
      transform: scale(1.05);
    }
  }
}

@mixin rounded-corners {
  border-radius: 12.5px;
}

@mixin section-spacing {
  padding-top: 25px;
  padding-bottom: 25px;
  margin-top: 25px;
  margin-bottom: 25px;
}

@mixin btn-primary {
  background-color: $primary-500;
  color: $neutral-100;
  background-image: none;
  width: 100%;
  border: none;
  text-transform: uppercase;
  text-shadow: none;
  font-weight: bold;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 1px;
  padding: 15px 25px;

  @media screen and (min-width: $break-medium) {
    max-width: 368px;
  }

  &:hover, 
  &:active,
  &:not(:disabled):not(.disabled):active {
    background-color: $primary-500;
    border-color: $primary-500;
    opacity: 0.8;
  }

  &[disabled] {
    cursor: default;
    background-color: $lightGray;
    opacity: 1;

    a {
      color: $white;
    }
  }

  //WTE color is dark enough to function as the heading color
  @if ($provider == 'wtebump') {
    background-color: $primary-color;
  }
}

@mixin btn-secondary {
  background-color: $white;
  border: 3px solid $primary-500;
  color: $black;
  background-image: none;
  text-transform: uppercase;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 0.9rem;
  text-shadow: none;
  width: 100%;
  padding: 13px 25px;
  font-weight: bold;

  @media screen and (min-width: $break-medium) {
    max-width: 368px;
  }

  //WTE color is dark enough to function as the heading color
  @if ($provider == 'wtebump' or $provider == 'pnmbump') {
    color: $neutral-100;
  }

  &:hover, 
  &:active,
  &:not(:disabled):not(.disabled):active
   {
    background-color: $white;
    border: 3px solid $primary-500;
    color: $black;
    opacity: 0.8;
  }

  &.btn-lg {
    font-size: 1.4em;
  }
}

@mixin btn-checkout {
  background-color: $success-300;
  color: $white;
  background-image: none;
  width: 100%;
  height: 50px;
  border: none;
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 1.2em;
  padding: 0;

  @media screen and (min-width: $break-medium) {
    max-width: 368px;
  }

  &:hover {
    background-color: $success-300;
    color: $white;
    opacity: 0.8;
  }
}

@mixin btn-textLink {
  background-color: transparent;
  color: $tertiary-600;
  background-image: none;
  max-width: 368px;
  font-weight: bold;
  font-family: 'Open Sans', Helvetica, sans-serif;
  font-size: 0.9em;
  padding: 0px;
  margin: 0px;
  border: none;

  svg {
    margin: 0px;
  }
}

@mixin slashedPrice {
  font-weight: normal;
  position: relative;
  display: inline-block;
  margin-right: 5px;
 
  &::before, &::after {
    content: '';
    width: 100%;
    position: absolute;
    right: 0;
    top: 50%;
  }

  &::after {
    border-bottom: 2px solid $primary-500;
    -webkit-transform: skewY(10deg);
    transform: skewY(10deg);
  }
}

@mixin loading($height) {
  @include rounded-corners;
  min-width: 80%;
  min-height: $height;
  margin-bottom: 6px;
  animation: loading 2s infinite;
  background: linear-gradient(to right, $neutral-150 4%, $neutral-200 25%, $neutral-150 36%);
  background-size: 1000px 100%;
  
  &.medium {
    min-height: 28px;
    min-width: 60%;
  }

  &.small {
    min-height: 18px;
    min-width: 40%;
  }
}


@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
