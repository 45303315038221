@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.emailInputAndPhone {
  text-align: left;
}
.emailInputAndPhone .signUpTextShipping {
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 5px;
}

.phonenumberInput p {
  font-size: 12px;
  margin-top: -1rem;
  padding-left: 10px;
  margin-bottom: 0;
}
