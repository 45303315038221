@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.asSeenIn {
  text-align: center;
  color: #000000;
  padding: 20px;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  align-content: center;
  background-color: #F0F9F8;
}
@media (max-width: 767px) {
  .asSeenIn {
    flex-wrap: wrap;
    flex-direction: row;
  }
}
.asSeenIn div {
  width: 8%;
}
@media (max-width: 767px) {
  .asSeenIn div {
    width: 30%;
    margin-bottom: 18px;
  }
  .asSeenIn div.breastPumpBanner {
    width: 20%;
    margin-bottom: 0;
  }
  .asSeenIn div.hideFromMobile {
    display: none;
  }
}
.asSeenIn img {
  width: 100%;
  height: auto;
}
.asSeenIn h2 {
  color: #000000;
  font-weight: bold;
  text-transform: uppercase;
  font-size: 16px;
  margin-bottom: 9px;
}
@media (max-width: 767px) {
  .asSeenIn h2 {
    width: 95%;
  }
}
