@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.date-form .dateInput {
  margin-bottom: 10px;
}
.date-form .dateInput .Select-menu-outer {
  background-color: white;
  z-index: 5;
}
.date-form .dateInput .Select-placeholder {
  font-size: 0.9em;
}

.date-form .help-block {
  color: red;
}
