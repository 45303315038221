@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.crossPromoBlockContainer {
  border: 1px solid gray;
  border-radius: 5px;
  background-color: #C3E9E2;
  text-align: center;
}
@media (max-width: 767px) {
  .crossPromoBlockContainer {
    border: none;
  }
}
.crossPromoBlockContainer button {
  font-size: 20px;
  color: white;
}
