@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.ctaButton {
  background-color: #3C7C70;
  color: #FFFFFF;
  background-image: none;
  width: 100%;
  border: none;
  text-transform: uppercase;
  text-shadow: none;
  font-weight: bold;
  font-family: "Open Sans", Helvetica, sans-serif;
  font-size: 0.9rem;
  letter-spacing: 1px;
  padding: 15px 25px;
  width: 100%;
  font-size: 1.2em;
}
@media screen and (min-width: 992px) {
  .ctaButton {
    max-width: 368px;
  }
}
.ctaButton:hover, .ctaButton:active, .ctaButton:not(:disabled):not(.disabled):active {
  background-color: #3C7C70;
  border-color: #3C7C70;
  opacity: 0.8;
}
.ctaButton[disabled] {
  cursor: default;
  background-color: #F4F4F4;
  opacity: 1;
}
.ctaButton[disabled] a {
  color: #FFFFFF;
}

@media (max-width: 992px) {
  .blackButton {
    width: 95%;
  }
}

.whiteButton {
  background-color: #FFFFFF;
  color: #231F20;
}
