@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.productDetailModal .modal-dialog {
  max-width: 80%;
  margin: 10px auto;
}
.productDetailModal .modal-dialog .modal-body {
  overflow: auto;
  max-height: 75vh;
}
@media (max-width: 767px) {
  .productDetailModal .modal-dialog .modal-body {
    text-align: center;
  }
}
@media screen and (min-width: 1280px) {
  .productDetailModal .modal-dialog .modal-body .externalImage {
    max-width: 400px;
    margin: 0px auto;
    display: block;
  }
}
.productDetailModal .modal-dialog .modal-footer {
  justify-content: center;
}
.productDetailModal .modal-dialog .close-details {
  cursor: pointer;
}
.productDetailModal .slashedPrice {
  font-weight: normal;
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
.productDetailModal .slashedPriceSlashed {
  font-weight: normal;
  position: relative;
  display: inline-block;
  margin-right: 5px;
}
.productDetailModal .slashedPriceSlashed::before, .productDetailModal .slashedPriceSlashed::after {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.productDetailModal .slashedPriceSlashed::after {
  border-bottom: 2px solid #3C7C70;
  -webkit-transform: skewY(10deg);
  transform: skewY(10deg);
}
