@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.progressBarCheckout .previousStepRow {
  justify-content: center;
}
.progressBarCheckout .progressBarContainer {
  margin-bottom: 150px;
}
.progressBarCheckout .returnBtn {
  color: #231F20;
  text-decoration: underline;
  font-size: 12px;
  border: none;
  background: white;
}
.progressBarCheckout .progressBarRow {
  text-align: center;
}
.progressBarCheckout .progressBarRow .arrowFirst {
  clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%);
}
.progressBarCheckout .progressBarRow .arrowMiddle {
  clip-path: polygon(0% 0%, 93% 0%, 100% 50%, 93% 100%, 0% 100%, 7% 50%);
}
.progressBarCheckout .progressBarRow .arrowLast {
  clip-path: polygon(0 0, 100% 0, 100% 50%, 100% 100%, 0 100%, 7% 50%);
}
.progressBarCheckout .progressBarRow .progressTextHighlight {
  background-color: #A3DED2;
  background-color: #75BDB0;
}
.progressBarCheckout .progressBarRow .progressTextNormal {
  background-color: #C3E9E2;
}
.progressBarCheckout .progressBarRow .progressBarButton {
  color: #231F20;
  text-transform: capitalize;
  background: none;
  border: none;
  margin-top: 8px;
}
@media screen and (min-width: 992px) {
  .progressBarCheckout .progressBarRow .progressBarButton {
    margin-bottom: 0;
  }
}
.progressBarCheckout #regularCheckout {
  padding: 0;
}
