@import './provider.scss';

// 2021 Colors - Global
$bumpBlack: #231F20;
$neutral-100: #FFFFFF;
$neutral-150: #F4F4F4;
$neutral-200: #D6D6D6;
$neutral-250: #B8B8B8;
$neutral-300: #95989A;
$neutral-400: #707070;
$neutral-500: #474747;
$neutral-600: #000000;

$success-100: #71C186;
$success-200: #4BAA64;
$success-300: #38804B;

$danger-100: #FF7075;
$danger-200: #FF474E;
$danger-300: #E00007;

$warning-100: #FFEB99;
$warning-200: #FFDA47;
$warning-300: #FFCE02;

// 2024 Colors - BumpBoxes
$bump-primary-100: #FFEBF3;
$bump-primary-200: #FFD8E6;
$bump-primary-300: #FC9CC3;
$bump-primary-400: #F058A0;
$bump-primary-500: #E40189;
$bump-primary-600: #C60176;
$bump-primary-50: #fcf9fa;

$bump-secondary-100: #F2FDEC;
$bump-secondary-200: #D7FAC7;
$bump-secondary-300: #B2F594;
$bump-secondary-400: #95F16A;
$bump-secondary-500: #40A810;
$bump-secondary-600: #32830C;

$bump-tertiary-100: #ECF2FD;
$bump-tertiary-200: #C7D7FA;
$bump-tertiary-300: #99B2F0;
$bump-tertiary-400: #7CA2F3;
$bump-tertiary-500: #326DEC;
$bump-tertiary-600: #134ECD;

// 2021 Colors - BitsyBoxes
$bitsy-primary-100: #F0F9F8;
$bitsy-primary-200: #C3E9E2;
$bitsy-primary-300: #A3DED2;
$bitsy-primary-400: #75BDB0;
$bitsy-primary-500: #3C7C70;
$bitsy-primary-600: #2E6057;

$bitsy-secondary-100: #F0E1F4;
$bitsy-secondary-200: #E2C3E9;
$bitsy-secondary-300: #D2A3DE;
$bitsy-secondary-400: #C487D4;
$bitsy-secondary-500: #A74BBE;
$bitsy-secondary-600: #903BA5;

$bitsy-tertiary-100: #F4F0E1;
$bitsy-tertiary-200: #E9E2C3;
$bitsy-tertiary-300: #DED2A3;
$bitsy-tertiary-400: #C9B669;
$bitsy-tertiary-500: #877631;
$bitsy-tertiary-600: #695C26;

$prenatal-primary-100: #ECC0C7;
$prenatal-primary-200: #E3A0AA;
$prenatal-primary-300: #D77483;
$prenatal-primary-400: #C84155;
$prenatal-primary-500: #BE374B;
$prenatal-primary-600: #9E2E3F;

@function set-color($colorName) {
  @if ($provider == 'bump') {
    @if ($colorName == 'primary') {
      @return $bump-primary-300;
    } @else if ($colorName == 'primary-100') {
      @return $bump-primary-100;
    } @else if ($colorName == 'primary-200') {
      @return $bump-primary-200;
    } @else if ($colorName == 'primary-300') {
      @return $bump-primary-300;
    } @else if ($colorName == 'primary-400') {
      @return $bump-primary-400;
    } @else if ($colorName == 'primary-500') {
      @return $bump-primary-500;
    } @else if ($colorName == 'primary-600') {
      @return $bump-primary-600;
    } @else if ($colorName == 'primary-50') {
      @return $bump-primary-50;
    } @else if ($colorName == 'secondary') {
      @return $bump-secondary-300;
    } @else if ($colorName == 'secondary-100') {
      @return $bump-secondary-100;
    } @else if ($colorName == 'secondary-200') {
      @return $bump-secondary-200;
    } @else if ($colorName == 'secondary-300') {
      @return $bump-secondary-300;
    } @else if ($colorName == 'secondary-400') {
      @return $bump-secondary-400;
    } @else if ($colorName == 'secondary-500') {
      @return $bump-secondary-500;
    } @else if ($colorName == 'secondary-600') {
      @return $bump-secondary-600;
    } @else if ($colorName == 'tertiary') {
      @return $bump-tertiary-300;
    } @else if ($colorName == 'tertiary-100') {
      @return $bump-tertiary-100;
    } @else if ($colorName == 'tertiary-200') {
      @return $bump-tertiary-200;
    } @else if ($colorName == 'tertiary-300') {
      @return $bump-tertiary-300;
    } @else if ($colorName == 'tertiary-400') {
      @return $bump-tertiary-400;
    } @else if ($colorName == 'tertiary-500') {
      @return $bump-tertiary-500;
    } @else if ($colorName == 'tertiary-600') {
      @return $bump-tertiary-600;
    } @else if ($colorName == 'checkout') {
      @return $bump-primary-400;
    } @else if ($colorName == 'warning') {
      @return $bump-primary-500;
    } @else if ($colorName == 'notice') {
      @return $bump-primary-600;
    } @else {
      @return $bump-primary-400;
    }
  } @else if ($provider == 'wtebump') {
    @if ($colorName == 'primary') {
      @return #603091;
    } @else if ($colorName == 'secondary') {
      @return #432C6C;
    } @else if ($colorName == 'tertiary') {
      @return #EEEBF4;
    } @else if ($colorName == 'checkout') {
      @return #603091;
    } @else if ($colorName == 'warning') {
      @return #7071DF;
    } @else if ($colorName == 'notice') {
      @return #7071DF;
    } @else {
      @return #432C6C;
    }
  } @else if ($provider == 'pnmbump') {
    @if ($colorName == 'primary') {
      @return #ec008c;
    } @else if ($colorName == 'secondary') {
      @return #a60063;
    } @else if ($colorName == 'tertiary') {
      @return #fdebf6;
    } @else if ($colorName == 'checkout') {
      @return #f8a3d6;
    } @else if ($colorName == 'warning') {
      @return #a60063;
    } @else if ($colorName == 'notice') {
      @return #a60063;
    } @else {
      @return #a60063;
    }
  } @else {
    @if ($colorName == 'primary') {
      @return $bitsy-primary-300;
    } @else if ($colorName == 'primary-100') {
      @return $bitsy-primary-100;
    } @else if ($colorName == 'primary-200') {
      @return $bitsy-primary-200;
    } @else if ($colorName == 'primary-300') {
      @return $bitsy-primary-300;
    } @else if ($colorName == 'primary-400') {
      @return $bitsy-primary-400;
    } @else if ($colorName == 'primary-500') {
      @return $bitsy-primary-500;
    } @else if ($colorName == 'primary-600') {
      @return $bitsy-primary-600;
    } @else if ($colorName == 'secondary') {
      @return $bitsy-secondary-300;
    } @else if ($colorName == 'secondary-100') {
      @return $bitsy-secondary-100;
    } @else if ($colorName == 'secondary-200') {
      @return $bitsy-secondary-200;
    } @else if ($colorName == 'secondary-300') {
      @return $bitsy-secondary-300;
    } @else if ($colorName == 'secondary-400') {
      @return $bitsy-secondary-400;
    } @else if ($colorName == 'secondary-500') {
      @return $bitsy-secondary-500;
    } @else if ($colorName == 'secondary-600') {
      @return $bitsy-secondary-600;
    } @else if ($colorName == 'tertiary') {
      @return $bitsy-tertiary-300;
    } @else if ($colorName == 'tertiary-100') {
      @return $bitsy-tertiary-100;
    } @else if ($colorName == 'tertiary-200') {
      @return $bitsy-tertiary-200;
    } @else if ($colorName == 'tertiary-300') {
      @return $bitsy-tertiary-300;
    } @else if ($colorName == 'tertiary-400') {
      @return $bitsy-tertiary-400;
    } @else if ($colorName == 'tertiary-500') {
      @return $bitsy-tertiary-500;
    } @else if ($colorName == 'tertiary-600') {
      @return $bitsy-tertiary-600;
    } @else if ($colorName == 'checkout') {
      @return $bitsy-primary-400;
    } @else if ($colorName == 'warning') {
      @return $bitsy-primary-500;
    } @else if ($colorName == 'notice') {
      @return $bitsy-primary-600;
    } @else {
      @return $bitsy-primary-400;
    }
  }
}

$primary-color: set-color('primary');
$primary-100: set-color('primary-100');
$primary-200: set-color('primary-200');
$primary-300: set-color('primary-300');
$primary-400: set-color('primary-400');
$primary-500: set-color('primary-500');
$primary-600: set-color('primary-600');
$primary-50: set-color('primary-50');
$secondary-color: set-color('primary-400');
$secondary-100: set-color('secondary-100');
$secondary-200: set-color('secondary-200');
$secondary-300: set-color('secondary-300');
$secondary-400: set-color('secondary-400');
$secondary-500: set-color('secondary-500');
$secondary-600: set-color('secondary-600');
$tertiary-color: set-color('primary-100');
$tertiary-100: set-color('tertiary-100');
$tertiary-200: set-color('tertiary-200');
$tertiary-300: set-color('tertiary-300');
$tertiary-400: set-color('tertiary-400');
$tertiary-500: set-color('tertiary-500');
$tertiary-600: set-color('tertiary-600');
$checkout-background-color: set-color('checkout');
$warning-link: set-color('warning');
$notice-me: set-color('notice');

$prenatal-pink: $prenatal-primary-300;
$payButton: $success-100;
$primaryText: $bump-primary-500;

$green: $success-100;

$darkGray: $neutral-300;
$lightGray: $neutral-150;

$ruleColor: $neutral-200;

$errorRed: $danger-300;
$testing: $neutral-600;

$black: $neutral-600;
$white: $neutral-100;
$whiteTransparent: rgba(255, 255, 255, 0.5);