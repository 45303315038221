@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.companyLocations-map iframe {
  width: 100%;
  height: 400px;
}
