@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.subContainer .subTitle {
  padding-top: 15px;
  font-size: 16px;
  font-weight: 600;
}
.subContainer .subFirstBoxPrice {
  font-size: 32px;
  font-weight: 700;
  margin-bottom: 0;
}
.subContainer .subBoxMobile {
  color: #231F20;
  padding: 10px;
  min-height: 75px;
  max-height: 400px;
  transition: all 0.2s;
}
@media screen and (min-width: 992px) {
  .subContainer .subBoxMobile:hover {
    transform: scale(1.05);
  }
}
@media screen and (min-width: 768px) {
  .subContainer .subBoxMobile {
    max-width: 650px;
    margin-top: 15px;
    margin-bottom: 0;
    margin-left: auto;
    margin-right: auto;
  }
  .subContainer .subBoxMobile .subArrow {
    height: 40px;
    width: 40px;
    margin-top: 70px;
  }
  .subContainer .subBoxMobile .subBoxHeader1 {
    font-size: 36px;
  }
}
@media screen and (min-width: 768px) and (max-width: 992px) {
  .subContainer .subBoxMobile .subBoxHeader1 {
    font-size: 42px;
  }
}
@media (max-width: 767px) {
  .subContainer .subBoxMobile {
    max-width: 650px;
    margin-top: 15px;
    margin-bottom: 0;
    margin-left: 0px;
    margin-right: 0px;
  }
}
.subContainer .subSelectionImage {
  margin-top: 15px;
}
.subContainer .subBoxSubContainer {
  line-height: 1.5;
}
@media screen and (min-width: 992px) {
  .subContainer .subBoxSubContainer {
    margin-top: 15px;
    margin-bottom: 15px;
  }
}
@media (max-width: 992px) {
  .subContainer .subBoxSubContainer {
    margin-top: 5px;
    margin-bottom: 5px;
  }
}
.subContainer .subBoxSubHeader {
  margin: 0 30px;
}
.subContainer .subBoxHeader1 {
  font-weight: normal;
  position: relative;
  display: inline-block;
  color: #95989A;
  font-size: 44px;
  margin-top: 10px;
  margin-bottom: 48px;
}
@media screen and (min-width: 992px) {
  .subContainer .subBoxHeader1 {
    margin-right: 5px;
  }
}
@media (max-width: 992px) {
  .subContainer .subBoxHeader1 {
    margin-right: 5px;
  }
}
.subContainer .subBoxHeader1::before,
.subContainer .subBoxHeader1::after {
  content: "";
  width: 100%;
  position: absolute;
  right: 0;
  top: 50%;
}
.subContainer .subBoxHeader1::after {
  border-bottom: 6px solid #3C7C70;
  -webkit-transform: skewY(10deg);
  transform: skewY(10deg);
}
.subContainer .actualCostHeader {
  position: relative;
  display: inline-block;
  font-weight: 600;
  margin: 0px auto;
  width: 100%;
  font-size: 16px;
  color: #3C7C70;
}
.subContainer .subPlanCost {
  font-size: 14px;
  color: #707070;
}
.subContainer .subSelectButton {
  margin-bottom: 15px;
  width: 90%;
}
.subContainer .subSelectButton.btn-primary {
  font-size: 16px;
  /* WTE color is dark enough */
}
.subContainer .selectSubNote {
  font-size: 14px;
}
.subContainer .extremelyBold {
  font-weight: 900;
}
.subContainer .textHighlight {
  color: #3C7C70;
  font-size: 44px;
}
.subContainer .bulletpointContainer {
  padding-left: 40px;
}
.subContainer .strikeThrough {
  text-decoration: line-through;
}
.subContainer .circleTag {
  position: absolute;
  width: 80px;
  height: 80px;
  border-radius: 50px;
  top: 10px;
  right: -15px;
  padding: 20px 5px;
  z-index: 3;
  background-color: #231F20;
  color: #FFFFFF;
  font-weight: 700;
  text-align: center;
}
.subContainer .circleTag p {
  font-size: 16px;
  margin-bottom: 0;
}
.subContainer .circleTag span {
  display: block;
  font-size: 12px;
  margin-top: -10px;
}

.subscriptionTag {
  position: absolute;
  width: calc(100% + 6px);
  text-align: center;
  z-index: 3;
  left: -3px;
  top: -3px;
  padding: 0px 20px;
  color: #FFFFFF;
  background-color: #877631;
  font-size: 16px;
  text-transform: uppercase;
  font-weight: bold;
  transition: all 1s linear 1s;
  border-radius: 6px 6px 0 0;
}
