@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.regular-header, .mobile-regular-header {
  padding: 30px;
  text-align: center;
}
.regular-header .circle, .mobile-regular-header .circle {
  height: 20px;
  width: 20px;
  border: 2px solid #A3DED2;
  border-radius: 15px;
  display: inline-block;
  margin-right: 8px;
  margin-left: 8px;
}
.regular-header .filled, .mobile-regular-header .filled {
  background-color: #A3DED2;
}
.regular-header .isHidden, .mobile-regular-header .isHidden {
  display: none;
}
