@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.mainPageContainer {
  max-width: 1600px;
  width: 100%;
  overflow-x: hidden;
  margin-left: auto;
  margin-right: auto;
  padding-left: 0px;
  padding-right: 0px;
}
@media screen and (min-width: 992px) {
  .mainPageContainer {
    min-height: 100vh;
  }
}
@media (max-width: 992px) {
  .mainPageContainer {
    /* if these padding values change, also change the header -X padding */
    padding-left: 15px;
    padding-right: 15px;
  }
}
@media (max-width: 992px) {
  .mainPageContainer .hero-container-left {
    margin-top: 45px;
    background-size: 100%;
  }
}
.mainPageContainer .hero-left h1 {
  font-size: 32px;
  line-height: 1.2;
}
@media (max-width: 992px) {
  .mainPageContainer .hero-left h1 {
    margin-bottom: 0;
    padding-top: 0;
  }
}
.mainPageContainer .aLookInside {
  background-size: contain;
  background-position: center;
  background-repeat: no-repeat;
  width: 100%;
  background-image: url("../../images/bitsy/look-inside/Bitsy_Look_Inside.jpg");
  margin-right: auto;
  margin-left: auto;
}
.mainPageContainer .selectSubscriptionContainer {
  text-align: center;
}
@media (max-width: 767px) {
  .mainPageContainer .selectSubscriptionContainer {
    /* TODO: Do this without a negative margin. This corresponds to the
     * overall homepage padding to allow the background to show to the sides
     * of the page
     */
    margin: 20px -15px;
    padding-left: 15px;
    padding-right: 15px;
    padding-bottom: 25px;
  }
}
@media screen and (min-width: 992px) {
  .mainPageContainer .selectSubscriptionContainer {
    min-height: 800px;
  }
}
.mainPageContainer .freeShippingTextContainer {
  background-color: #C3E9E2;
  border-radius: 4px;
  font-weight: 700;
  text-align: center;
  padding: 15px;
  margin: 15px;
}
