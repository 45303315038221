@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.countdownContainer {
  width: 100%;
  padding-bottom: 5px;
}
.countdownContainer .timerRow {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}
.countdownContainer .timerRow .timerCol {
  display: inherit;
  justify-content: center;
}
.countdownContainer .timerRow .timer {
  background: #231F20;
  color: #FFFFFF;
  border-radius: 5px;
  justify-content: center;
  align-items: center;
  text-align: center;
  margin: 5px;
  padding: 4px;
  font-size: 18px;
  font-weight: 700;
}
.countdownContainer .timerRow .minutesText, .countdownContainer .timerRow .secondsText {
  display: block;
  justify-content: center;
  text-align: center;
  font-weight: 700;
}
@media (max-width: 767px) {
  .countdownContainer .timerRow .minutesText, .countdownContainer .timerRow .secondsText {
    font-size: 14px;
  }
}
.countdownContainer .exitButtonRow {
  text-align: right;
}
