@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.comboAutoComplete {
  margin-bottom: 1rem;
  flex-wrap: nowrap;
}
.comboAutoComplete input {
  padding: 5px 10px;
  height: 42px;
  color: black !important;
  border: 1px solid #95989A;
  font-size: 14px;
  text-shadow: none;
}
.comboAutoComplete .dropdown-item {
  text-decoration: none;
}
.comboAutoComplete .toggle {
  background: transparent;
  border: 0;
  bottom: 0;
  padding: 0 12px;
  position: absolute;
  right: 0;
  top: 0;
}
