@import '../../styles/styles.scss';

.shipping-insurance-container {
  line-height: normal;
  padding-left: 30px;
  margin-bottom: 15px;
  margin-right: 5px;

  .input-group-text {
    box-shadow: none !important;
    background: white;
    border: none;
    padding-top: 0;
  }
}
