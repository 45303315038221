@import '../../styles/styles.scss';

.preCheckoutPromo {
  padding: 15px;
  padding-top: 0;

  .productHeader, .productSubHeader {
    text-align: left;
    line-height: 1.5;
  }

  .productHeader, .productName {
    font-size: 14px;
    margin-bottom: 0;
  }

  .productSubHeader, .productBrand {
    font-size: 12px;
    font-weight: 400;
  }

  .productCol {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: left;
    padding: 20px;
    padding-left: 15px;
  }

  .addOfferButton {
    max-width: 100%;
  }

  .price {
    position: relative;
    color: $primaryText; 
    display: inline-block;
    font-weight: bold;
    font-size: 20px;
    text-align: left;

    &Slashed {
      @include slashedPrice;
    }
  }

  //reviews text for yotpo
  .text-m {
    display: none;
  }

  // aligns yotpos star ratings
  #starRating {
    justify-content: center !important;
    text-align: center !important;
    display: flex;
  }
}
