@keyframes loading {
  0% {
    background-position: -1000px 0;
  }
  100% {
    background-position: 1000px 0;
  }
}
.credit-card-container .payment-loader {
  display: inline-block;
  width: 80px;
  height: 80px;
}
.credit-card-container .payment-loader:after {
  content: " ";
  display: block;
  width: 64px;
  height: 64px;
  margin: 8px;
  border-radius: 50%;
  border: 6px solid #A3DED2;
  border-color: #A3DED2 transparent #A3DED2 transparent;
  animation: payment-loader 1.2s linear infinite;
}
@keyframes payment-loader {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.credit-card-form-component .StripeElement {
  display: block;
  border: 1px solid #ccc;
  outline: 0;
  background: white;
  border-radius: 10px;
}
.credit-card-form-component input:focus,
.credit-card-form-component .StripeElement--focus {
  background-color: white !important;
  box-shadow: rgba(50, 50, 93, 0.109804) 0px 4px 6px, rgba(0, 0, 0, 0.0784314) 0px 1px 3px;
  -webkit-transition: all 150ms ease;
  transition: all 150ms ease;
}
.credit-card-form-component .checkoutCardNumber {
  min-width: 200px;
}
.credit-card-form-component .stripeInput {
  border-radius: 8px;
  border: 1px solid #95989A;
  display: block;
  width: 100%;
  height: calc(1.5em + 0.75rem + 20px);
  font-size: 18px;
  font-weight: 400;
  line-height: 1.5;
  padding-top: 20px;
  text-align: left;
}
.credit-card-form-component .creditCard-Col {
  padding: 5px 15px 10px 15px;
}
.credit-card-form-component .creditCard-Col span {
  display: inline-block;
  position: absolute;
  padding-left: 12px;
  left: 15px;
  top: 20px;
  transition: all 150ms ease-in;
  line-height: 1.5;
  color: #231F20;
  text-transform: capitalize;
  font-size: 18px;
}
.credit-card-form-component .creditCard-Col span.cardNumber,
.credit-card-form-component .creditCard-Col span.cardExpiry,
.credit-card-form-component .creditCard-Col span.cardCvc,
.credit-card-form-component .creditCard-Col span.postalCode {
  transform: translateY(-10px);
  font-size: 0.8em;
  color: #231F20;
  font-weight: 400;
}
.credit-card-form-component .creditCard-Col .helperText {
  font-size: 12px;
  text-align: left;
  padding-left: 12px;
  margin-bottom: 0;
}
.credit-card-form-component .credit-card-fields {
  margin-bottom: 35px;
}
.credit-card-form-component .creditCardButton {
  background: none !important;
  border: none !important;
  padding: 10px 0 10px 0 !important;
  text-decoration: none;
  margin-top: 10px;
  color: #71C186;
  white-space: normal;
  box-shadow: none;
}
.credit-card-form-component .creditCardButton:hover {
  text-decoration: none;
  color: #95989A;
  cursor: pointer;
}
.credit-card-form-component .creditCardButton:focus {
  outline: dotted;
}

.payment-request-component {
  padding-top: 10px;
}
.payment-request-component .creditCardButton {
  background: none !important;
  border: none !important;
  padding: 10px 0 10px 0 !important;
  text-decoration: none;
  margin-top: 10px;
  color: #75BDB0;
  white-space: normal;
  box-shadow: none;
}
.payment-request-component .creditCardButton:hover {
  text-decoration: none;
  color: #95989A;
  cursor: pointer;
}
.payment-request-component .creditCardButton:focus {
  outline: dotted;
}
.payment-request-title {
  text-align: center;
  margin-bottom: 14px;
  text-transform: inherit;
}
.payment-request-divider {
  position: relative;
}
.payment-request-divider-title {
  position: absolute;
  top: -12px;
  background-color: white;
  margin: 0 auto;
  left: 50%;
  padding: 0px 20px;
  transform: translate(-50%, 0%);
}

.usePreviousCard {
  border: 1px solid #95989A;
  border-radius: 8px;
  padding: 10px;
  margin: 5px 0;
}
.usePreviousCard .cardEnding {
  margin-top: -20px;
  margin-bottom: 0px;
  font-weight: normal;
}

.billing-address-container {
  text-align: left;
  border: 1px solid #D6D6D6;
  border-radius: 8px;
  padding: 5px 0;
  margin: 0;
  margin-bottom: 15px;
}
.billing-address-container .form-check-label {
  font-weight: 400;
}
.billing-address-container .billingAddressLabel {
  margin-bottom: 0 !important;
}
.billing-address-container hr {
  margin: 2px -15px;
  border-top: 1px solid #D6D6D6;
}

.checkbox-container {
  padding: 10px 0px;
  margin: 0;
  font-size: 14px;
  line-height: 1.5;
}
.checkbox-container .row {
  margin-bottom: 10px;
}
